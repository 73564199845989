import React from 'react'
import { auditSubject } from './SpinnerService'
import { auditTime } from 'rxjs'

export default function Spinner (): React.JSX.Element {
  const [display, setDisplay] = React.useState<boolean>(false)

  React.useEffect(() => {
    const localSubscription = auditSubject.pipe(auditTime(350)).subscribe((newState: boolean) => {
      setDisplay(newState)
    })

    return () => {
      localSubscription.unsubscribe()
    }
  }, [])

  return (
    <>
      {display &&
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      }
    </>
  )
}
