import './App.css';
import RootContainer from './RootContainer';

function App() {
  return (
    <RootContainer></RootContainer>
  );
}

export default App;
