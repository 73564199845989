import * as React from 'react'
import Box from '@mui/material/Box'
import { kcReady, keycloak } from './keycloak'
import AuthenticationSpinner from './component/AuthenticationSpinner'
import { spinnerSubject } from './component/SpinnerService'
import PdfForm from './PdfForm'

export default function RootContainer (): React.JSX.Element {
  const [connected, setConnected] = React.useState<boolean>(false)

  React.useEffect(() => {
    spinnerSubject.next(true)
    keycloak.onTokenExpired = () => {
      keycloak.clearToken()
    }
    kcReady.subscribe(() => {
      spinnerSubject.next(false)
      setConnected(true)
    })
  }, [])

  if (!connected) {
    return <AuthenticationSpinner/>
  }

  return (
    <>
        <div className='header'>
          <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
            <PdfForm/>
          </Box>
        </div>
    </>
  )
}
