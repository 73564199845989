import Keycloak, { type KeycloakOnLoad } from 'keycloak-js'
import { ReplaySubject } from 'rxjs'
import { type appConf } from './appConf'

declare let conf: appConf

const initOptions = {
  url: conf.url,
  realm: conf.realm,
  clientId: conf.clientId,
  onLoad: 'login-required' as KeycloakOnLoad,
  KeycloakResponseType: 'code'
}

export const keycloak = new Keycloak(initOptions)
export const kcReady = new ReplaySubject<boolean>()

if (process.env.NODE_ENV.toString() === 'development' &&
  conf.url !== "https://auth.blo.dayploy.fr") {
  keycloak.authenticated = true;
  keycloak.token = 'eyddd'
  console.error('Authentification DEV')
  kcReady.next(true)
} else {
  keycloak.init({
    onLoad: initOptions.onLoad,
    checkLoginIframe: false,
    pkceMethod: 'S256'
  }).then((auth) => {
    if (!auth) {
      console.error('Authentification KO')
    } else {
      keycloak.onTokenExpired = () => {
        console.error('token expired')
      }
      kcReady.next(true)
    }
  }, () => {
    console.error('Authenticated Failed')
  })
}
