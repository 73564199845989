export async function handleFetchErrors(res: Response) {
  if (!res.ok) { // if HTTP status is 400 or above
    const errorData = await res.json(); // get JSON from the response
    let description = '';
    if ((errorData.hasOwnProperty('@type') && (errorData['@type'] === 'ConstraintViolationList') || errorData['@type'] === 'hydra:Error')) {
      description = errorData['hydra:description'];
    }

    throw new Error('Server responded with ' + res.status + ' ' + res.statusText + '. ' + description);
  }

  return res;
}