import React, { useState } from 'react';
import { message } from 'antd';
import { LoadingOutlined, InboxOutlined } from '@ant-design/icons';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import { appConf } from './appConf';
import { keycloak } from './keycloak';
import { handleFetchErrors } from './fetchUtils';
import Dragger from 'antd/es/upload/Dragger';
import { Button, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import SendIcon from '@mui/icons-material/Send'

declare let conf: appConf

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const PdfForm: React.FC = () => {
  const [uploading, setUploading] = useState(false);
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [fileNames, setFilenames] = useState<string[]>([]);

  const files: UploadFile[] = []

  const runConvert = () => {
    setUploading(true);
    const headers = {
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json'
    }

    const jsonData = {
      'fileIds': fileIds
    }

    fetch(conf.apiUrl + '/v1/runconvert', {
      method: 'POST',
      body: JSON.stringify(jsonData),
      headers: headers,
    })
      .then(handleFetchErrors)
      .then(() => {
        message.success('Demande de conversion transmise, vous allez recevoir un mail dans les minutes qui suivent');

        setFileIds([])
        setFilenames([])
      })
      .catch((error) => {
        message.error('Une erreur est survenue: ' + error.message);
      })
      .finally(() => {
        setUploading(false);
      });
  }

  const handleUpload = (file: UploadFile) => {
    const formData = new FormData();
    const tempFileIds = fileIds
    const tempFileNames = fileNames

    formData.append('file', file as FileType);
    tempFileNames.push(file.name)
    let myuuid = uuidv4();
    tempFileIds.push(myuuid)

    formData.append('fileId', myuuid);

    setFileIds(tempFileIds)
    setFilenames(tempFileNames)

    setUploading(true);
    const headers = {
      'Authorization': 'Bearer ' + keycloak.token
    }

    fetch(conf.apiUrl + '/v1/uploadfile', {
      method: 'POST',
      body: formData,
      headers: headers,
    })
      .then(handleFetchErrors)
      .catch((error) => {
        message.error('Une erreur est survenue: ' + error.message);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const props: UploadProps = {
    style: { width: '100%' },
    showUploadList: false,
    multiple: true,
    beforeUpload: (file) => {
      handleUpload(file)
      return false;
    },
    fileList: files,
  };

  return (
    <>
      <Grid container style={{ paddingTop: '10vh' }} className='pdf-form' alignItems="center" justifyContent="center">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ width: '500px' }}
        >
          <Dragger {...props} style={{ width: '500px', minHeight: '400px', display: 'flex', flexDirection: 'column' }} className='app-dragger'>
            <div style={{ flexGrow: 1 }}>
              <p className="ant-upload-drag-icon">
                {uploading ? <LoadingOutlined /> : <InboxOutlined />}
              </p>
              <p className="ant-upload-text">Cliquer ou glisser-déposer un fichier ici</p>
              <p className="ant-upload-hint">
                Seuls les fichiers PDF sont autorisés
              </p>
            </div>
          </Dragger>
          {fileNames.length > 0 &&
            <div>
              <h2>Les fichiers à convertir:</h2>
              <ul>
                {fileNames.map((filename) => {
                  return <li key={filename}>{filename}</li>
                })}
              </ul>
              <div style={{ marginTop: '5em' }}>
                <Button variant='contained' onClick={runConvert}>Lancer la conversion <SendIcon style={{ marginLeft: '0.5em' }} /></Button>
              </div>
            </div>
          }
        </Grid>
      </Grid>
    </>
  );
};

export default PdfForm;