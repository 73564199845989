import { Subject } from 'rxjs'

const spinnerSubject = new Subject<boolean>()
const auditSubject = new Subject<boolean>()

let counter = 0
spinnerSubject.subscribe((newState: boolean) => {
  if (newState) {
    counter++
  } else {
    counter--
  }

  if (counter > 0) {
    auditSubject.next(true)
  } else {
    auditSubject.next(false)
  }
})

export {
  spinnerSubject,
  auditSubject
}
